<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
        <v-row justify="start">
            <v-col cols="2" v-for="province in provinces" :key="province.id" class="viewCard">
                <router-link :to="{ name:'SurveyProvinceDivisions', params:{provinceId:province.provinceID}}">
                    <HoverCard :cardData="province">
                        <p class="Quote">{{$t('total_division')}} :{{province.totalDivision}}</p>
                    </HoverCard>

                    
                </router-link>
            </v-col>
        </v-row>
    </v-content>
</template>

<script>
    import axios from "axios";
    import HoverCard from '@/components/Basic/HoverCard'

    export default {
        name: "ProvinceList",
        components: {
            HoverCard
        },
        computed: {
            items() {
                return [
                    {
                        text: this.$t('dashboard'),
                        disabled: false,
                        to: "/SurveyDashboard",
                        exact: true
                    },
                    {
                        text: this.$t('provinces'),
                        disabled: true,
                        to: "/dashboard",
                        exact: true
                    }
                    ]
            }
            },
        data() {
            return {
                //items: [
                //    {
                //        text: this.$t('dashboard'),
                //        disabled: false,
                //        to: "/SurveyDashboard",
                //        exact: true
                //    },
                //    {
                //        text: this.$t('provinces'),
                //        disabled: true,
                //        to: "/dashboard",
                //        exact: true
                //    }],
                provinces: [],
            }
        },
        mounted() {
            this.getProvince()
        },
        methods: {
            async getProvince() {
                var provNepali = localStorage.getItem('lang');
                if (provNepali == 'ne') {
                    try {
                        const response = await axios.get("Province/GetProvinceListNepaliByMinistry");
                        this.provinces = response.data.map(res => ({ ...res, name: res.provinceName, icon: "mdi-earth-box" }))

                    } catch (e) {
                        if (e.response.data.State == "AccessTokenExpired") {
                            this.apiResponse(e);
                            this.loadItems();
                        }
                    }
                }
                else {
                    try {
                        const response = await axios.get("Province/GetProvinceListByMinistry");
                        this.provinces = response.data.map(res => ({ ...res, name: res.provinceName, icon: "mdi-earth-box" }))

                    } catch (e) {
                        if (e.response.data.State == "AccessTokenExpired") {
                            this.apiResponse(e);
                            this.loadItems();
                        }
                    }
                }
                
            },
            selectProvince(provinceID) {
                console.log(provinceID)
                this.$emit('selectProvince', provinceID)
            }
        }
    };
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
    }
</style>